import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ThankYouPage() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const refNumber = query.get('ref');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const now = new Date();
    const dateString = now.toLocaleDateString();
    const timeString = now.toLocaleTimeString();
    const dateTimeStamp = `${dateString} ${timeString}`;

    const isSmallScreen = windowWidth <= 768;

    const styles = {
        pageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //   minHeight: '100vh',
            padding: '20px',
            boxSizing: 'border-box',
        },
        container: {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            padding: isSmallScreen ? '20px' : '40px',
            textAlign: 'center',
            maxWidth: isSmallScreen ? '100%' : '700px',
            width: '100%',
            boxSizing: 'border-box',
            //   marginTop: '50px', // Added top margin
        },
        checkmarkCircle: {
            width: isSmallScreen ? '80px' : '100px',
            height: isSmallScreen ? '80px' : '100px',
            margin: '0 auto 20px',
        },
        heading: {
            color: '#2ecc71',
            marginBottom: '10px',
            fontSize: isSmallScreen ? '1.8rem' : '2.2rem',
        },
        subheading: {
            color: '#333',
            marginBottom: '20px',
            fontSize: isSmallScreen ? '1rem' : '1.2rem',
        },
        text: {
            color: '#333',
            lineHeight: '1.6',
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
            marginBottom: '20px',
        },
        confirmationDetails: {
            backgroundColor: '#f8f8f8',
            borderRadius: '4px',
            padding: '15px',
            marginBottom: '20px',
        },
        confirmationHeading: {
            color: '#333',
            fontSize: isSmallScreen ? '1rem' : '1.2rem',
            marginBottom: '10px',
        },
        button: {
            backgroundColor: '#3498db',
            color: 'white',
            border: 'none',
            padding: isSmallScreen ? '8px 16px' : '10px 20px',
            borderRadius: '4px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '20px',
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
            transition: 'background-color 0.3s',
        },
    };

    return (
        <div style={styles.pageContainer}>
            <div style={styles.container}>
                <div style={styles.checkmarkCircle}>
                    <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle className="checkmark-circle" cx="26" cy="26" r="25" fill="none" stroke="#2ecc71" strokeWidth="2" />
                        <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" stroke="#2ecc71" strokeWidth="2" />
                    </svg>
                </div>
                <h1 style={styles.heading}>Thank you!</h1>
                <p style={styles.subheading}>Application Successfully Submitted!</p>
                <p style={styles.text}>
                    Thank you for submitting your application. We are thrilled that you've chosen to apply and are looking forward to reviewing your submission.
                </p>
                <div style={styles.confirmationDetails}>
                    <h2 style={styles.confirmationHeading}>Confirmation Details:</h2>
                    <p style={styles.text}>Submission Date: {dateTimeStamp}</p>
                    <p style={styles.text}>Application Reference Number: {"#" + refNumber}</p>
                </div>
                <p style={styles.text}>
                    Please note down your application reference number for future reference. This will help us quickly locate your application should you have any questions or need to follow up with us.
                </p>
                <a
                    href="/"
                    style={styles.button}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#2980b9'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#3498db'}
                >
                    Back to Home
                </a>
            </div>
        </div>
    );
}
