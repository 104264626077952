import React from "react";
import term_banner from "../images/terms.jpg";

export default function TermsAndConditions(props) {
    return (
        <>
            <title>SalaryKart - {props.title}</title>
            <div className="inner-banner">
                <img src={term_banner} style={{ width: "100%" }} alt="terms" />
            </div>
            <div className="contact-line">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Terms & Conditions</h1>
                            <h2>User Contract</h2>
                            <p>By using our website and services, you accept the following terms and conditions. If you disagree with any part of these terms, please do not download, install, or use our services.</p>
                            <h2>Overview of the Agreement</h2>
                            <p>By using our website, you confirm that you have read and agree to this Agreement. This Agreement forms a binding legal contract between you ("Customer," "you," or "End-User") and SalaryKart ("Company," "we," or "us"). Your use of our platform and services, which include short-term loans, IT industry services, consultancy services, and manpower services, is governed by this Agreement. Please review these terms carefully to understand the conditions under which we provide our services. By continuing to use our platform, you acknowledge that you have read and agree to the terms of this Agreement. We are committed to providing clear and transparent terms to ensure a safe and effective service experience.</p>
                            <h2>Definitions</h2>
                            <p>Understanding these definitions will help you navigate and use the SalaryKart platform more effectively, ensuring a clear and straightforward experience:</p>
                            <ul>
                                <li><strong>Lender:</strong> Any financial organization that has a loan-sanctioning agreement with SalaryKart, including banks and non-bank financial institutions (NBFIs). Lenders oversee, approve, and distribute loans to clients using our platform.</li>
                                <li><strong>Business:</strong> A company formally established under the Companies Act of 2013, with its principal office located at Delhi.</li>
                                <li><strong>Client/You/End User:</strong> Anyone who uses, browses, shops, or engages in any other activity on our platform or associated services.</li>
                                <li><strong>Credit:</strong> The funds disbursed by the lender to users of our site, in accordance with the terms and conditions outlined in the loan contract.</li>
                                <li><strong>Loan Contract:</strong> A formal written or electronic contract signed by the lender and the borrower (or client), detailing all the terms and conditions of the loan.</li>
                                <li><strong>Outstanding Sum:</strong> The total amount, including interest and any associated costs, that borrowers must repay within the specified time limits.</li>
                                <li><strong>Webpage/System:</strong> The website where clients can avail themselves of our services.</li>
                                <li><strong>Services:</strong> The distribution, approval, and sanctioning of short-term loans, as well as IT industry, consulting, and personnel services, facilitated by our platform.</li>
                                <li><strong>Independent Platforms:</strong> Social networking sites or other online spaces like Facebook, LinkedIn, and other web pages not directly administered by SalaryKart.</li>
                                <li><strong>User Information:</strong> All data provided by clients to SalaryKart, both before and after using our services.</li>
                            </ul>
                            <p>You will be able to use the SalaryKart platform more effectively and ensure a clear and straightforward experience when using our loan services, IT industry services, consultancy services, and manpower services if you understand these definitions. We aim to make your interactions with us efficient and easy to understand by providing clear and accessible information. Familiarity with these terms will help you confidently navigate our platform and utilize the wide range of services we offer. SalaryKart is committed to upholding clarity and transparency in all of our dealings to earn your satisfaction and confidence in our services.</p>
                            <h2>Customer Obligations</h2>
                            <p>As a customer, you are responsible for accessing, utilizing, and engaging with our platform using accurate and truthful information. Ensure that all information you provide to SalaryKart is current and correct. Providing precise information is crucial for a seamless and effective customer experience.</p>
                            <h2>Loan Contract</h2>
                            <p>The loan contract outlines all terms and conditions of the loan, including the loan amount, interest rate, repayment schedule, and any other relevant information. It is essential to read and understand this agreement before proceeding with any loan.</p>
                            <h2>Outstanding Sum</h2>
                            <p>The outstanding sum refers to the total amount owed by the borrower, including the principal loan amount, interest, and any other associated costs. Adhering to the repayment schedule is crucial to avoid penalties and additional charges, maintain a good credit score, and ensure a smooth repayment process.</p>
                            <h2>Utilizing External Platforms</h2>
                            <p>SalaryKart may interact with social networking websites and other platforms not directly managed by us. We advise you to read the terms of use and privacy policies of these external platforms.</p>
                            <h2>User Information</h2>
                            <p>SalaryKart collects and manages client information to provide our services effectively. We are committed to protecting your privacy and ensuring that your information is handled securely and in compliance with legal regulations and industry standards.</p>
                            <h2>Terms of Service Agreement for SalaryKart</h2>
                            <p>By using our services, you accept the terms and conditions outlined in this Agreement. This document details the rights and responsibilities of SalaryKart and its users. Please read it carefully to understand the terms and conditions of using our services.</p>
                            <h2>Enrolment and Qualifications</h2>
                            <p>To use SalaryKart's services, you must meet the eligibility requirements listed on our website. SalaryKart collects and evaluates user data, supporting documentation, and other information to approve loans. This information is securely stored with the lender's consent. You must register by creating a "User Account" on an approved third-party platform to use our services. During registration, you will need to provide your name, email address, date of birth, phone number, username, password, photo, contact information, mobile phone data (including SMS and browsing history), bank account numbers, PAN card, customer compliance data, credit reporting data, and other relevant information. It is your responsibility to ensure that all your data is accurate, complete, and current. By using our services, you authorize SalaryKart to access and obtain any necessary data from third-party platforms, including personal data. You may request periodic updates to your data if needed.</p>
                            <h2>Gathering and Utilizing Data</h2>
                            <p>SalaryKart always obtains your consent before taking any action on your behalf. We do not use any judgment in conducting business. By accepting these terms, you permit SalaryKart to obtain credit records from various financial organizations. Your SalaryKart application will be reviewed and approved once the initial online form has been completed and submitted with the necessary user data, supporting documentation, and information. If you meet the eligibility requirements and agree to the terms and conditions, the lender may approve your loan request after our document validation process.</p>
                            <h2>Loan Applications and Payment</h2>
                            <p>To expedite the loan application process, you will need to complete and submit the ECS/NACH authorization form, a check, and any other pertinent documents requested by the lender. Our agents may collect paper documents requiring your signature to manage and approve loans. The loan funds will be disbursed once the lender has reviewed and accepted these documents according to the conditions of the loan agreement. The disbursement of the loan will be governed by the conditions mentioned in the online application.</p>
                            <h2>Compliance and Observance</h2>
                            <p>SalaryKart may monitor you even after you stop using our platform to ensure all your obligations are fulfilled until the outstanding balance is paid. We reserve the right to monitor your payments and transactions until the entire debt is paid off. Even if you deactivate or discontinue using our services, you are still liable for any outstanding amounts. You are entirely responsible for any activities associated with your user account. SalaryKart disclaims all liability for any disputes, claims, or damages arising from the proper or improper use of the services. You are responsible for maintaining the confidentiality of your User Account and any related activities.</p>
                            <h2>Requests for Services and Technical Needs</h2>
                            <p>SalaryKart reserves the right to accept or reject your service request at any time without explanation. You also understand that certain technical requirements must be met for the platform and services to function properly and ensure efficient communication. SalaryKart securely stores the information you entered when signing up, allowing you to access the site without re-entering your details. By accepting these conditions, you expressly grant us permission to collect, access, and use user and personal data for account updates and authentication.</p>
                            <h2>Privacy and Data Security</h2>
                            <p>SalaryKart is committed to protecting your data's privacy and security. We have robust security measures to prevent unauthorized access, use, or disclosure of your data. We collect and handle your data in compliance with all relevant laws and regulations. Your personal information is used solely to provide our services, comply with legal requirements, and improve customer satisfaction. We may share only the necessary information with other service providers, all of whom are subject to strict confidentiality agreements.</p>
                            <h2>User Accountabilities</h2>
                            <p>As a user, you are responsible for accessing, utilizing, and engaging with our platform using accurate and truthful information. Providing false or misleading information may result in the rejection of your application or termination of your account. You are also responsible for maintaining the security of your user account, monitoring any activity, and safeguarding your login information. If you suspect unauthorized use of your account or access, notify SalaryKart immediately.</p>
                            <h2>Updates and Modifications to the Contract</h2>
                            <p>SalaryKart may change this Agreement at any time. We will notify you of any significant changes by email or through our website. Continuing to use our services after receiving these notices will be considered acceptance of the updated terms.</p>
                            <h2>Contact Details</h2>
                            <p>If you have any questions or concerns about this Agreement or our services, please contact us at info@salarykart.com. We are here to assist you and ensure a positive and rewarding experience with SalaryKart.</p>
                            <h2>License Terms for Using the Website</h2>
                            <p>To use SalaryKart's services, you must visit our website. By using our services, you confirm that you are of legal age and have the mental capacity to read, understand, and agree to these terms. Subject to these terms and conditions, SalaryKart provides you with a limited, non-exclusive, non-transferable, and revocable license to access and use the website and its services. By utilizing the website, you accept these conditions. The SalaryKart website is designed to work with various electronic devices, such as tablets, smartphones, and similar devices. While SalaryKart strives to update and maintain the website, we cannot guarantee that support services will always be available. Although we make every effort to ensure that our platform remains accessible to all users, we cannot guarantee continuous service or support.</p>
                            <h2>Use Regulations and Directives</h2>
                            <p>To maintain a respectful, secure, and legal environment while using the SalaryKart platform and services, please adhere to the following rules. By following these guidelines, all users contribute to the security and integrity of our services.</p>
                            <h2>Activities Prohibited</h2>
                            <p>You agree not to engage in any illegal or criminal activities while using the SalaryKart platform or services.</p>
                            <h2>Reproduction and Exploitation</h2>
                            <p>No part of this website or its services may be reproduced, duplicated, replicated, sold, resold, or used for commercial purposes.</p>
                            <h2>Contentious Issues</h2>
                            <p>Uploading, posting, emailing, transmitting, or sharing any content that is offensive, vulgar, obscene, defamatory, abusive, harassing, tortuous, hurtful, threatening, or discriminatory based on race or ethnicity is strictly prohibited. SalaryKart is committed to fostering a respectful and professional environment.</p>
                            <h2>Unsolicited Communications</h2>
                            <p>Using our platform to disseminate unsolicited materials of any kind, including "spam," "junk mail," "chain letters," "pyramid schemes," and similar forms, is strictly prohibited. SalaryKart values the courteous and prudent use of our services.</p>
                            <h2>Harmful Content</h2>
                            <p>Uploading, posting, emailing, sharing, or distributing content that contains computer viruses or any other malicious code, files, or programs intended to obstruct, disable, or disrupt the operation of computer software, hardware, or telecommunications equipment is forbidden. Maintaining a reliable and secure environment is essential to us.</p>
                            <h2>Breach and Disruptions</h2>
                            <p>Engaging in any activity that could impede normal communication, prevent other users from participating in live discussions, or attempt to compromise the security of the SalaryKart platform or any of its related networks is strictly prohibited. We value maintaining a peaceful and safe environment and appreciate your commitment to these principles.</p>
                            <p>By following these rules, you help create a safe and respectful online community. SalaryKart is dedicated to ensuring every user has a satisfying and successful experience on our platform. Thank you for your cooperation and understanding.</p>
                            <h2>SalaryKart Services: Rule Observation</h2>
                            <p>To ensure a secure and lawful user experience on the SalaryKart platform, you must not violate any local, state, federal, or international laws or regulations, whether intentionally or accidentally. Failure to comply with these legal obligations may result in the termination of your access to SalaryKart services. We appreciate your dedication to upholding these legal standards.</p>
                            <h2>Disclaimer of Content</h2>
                            <p>SalaryKart disclaims all responsibility for the content accessed through its platform or services. This includes text, data files, numerical data, computer code, audio files, pictures, videos, and other visual elements. Users are solely responsible for any content they upload, post, link to, or otherwise make available on the platform. SalaryKart is not liable for any harm resulting from the use of inaccurate, misleading, or incomplete information. Unless otherwise specified in these terms, nothing on the platform should be construed as an official solicitation, endorsement, or recommendation to buy or sell any financial products or services offered by SalaryKart or its affiliates. We value your understanding of our content restrictions, which help maintain the platform's reliability and integrity.</p>
                            <h2>Enforcement and Consequences</h2>
                            <p>To maintain a secure and productive environment for all users, SalaryKart reserves the right to enforce certain usage policies and limitations. Necessary actions may include:</p>
                            <ul>
                                <li>Caution: Notifying the user of the specific violation.</li>
                                <li>Temporary Suspension: Temporarily removing your access to the platform and its services.</li>
                                <li>Permanent Ban: Permanently preventing you from using the website and its services.</li>
                                <li>Legal Action: Taking legal action if required to protect the safety and legal rights of SalaryKart users.</li>
                            </ul>
                            <p>Please report any violations of these rules to SalaryKart immediately. We will investigate any claims of misconduct and address any issues promptly and thoroughly.</p>
                            <h2>Privacy and Data Security</h2>
                            <p>SalaryKart is committed to protecting the security and privacy of your data. We have strong security measures in place to prevent unauthorized access, use, or disclosure of your information. By using our platform, you accept our data collection and privacy practices as outlined in our Privacy Policy. We are dedicated to ensuring that your data is handled securely and lawfully to enhance your trust and confidence in our services.</p>
                            <h2>Service Offerings</h2>
                            <h3>Quick Loans</h3>
                            <p>We offer quick and flexible financing options to address urgent financial needs. Our short-term loans are designed to help businesses manage cash flow, expand, or cover unexpected expenses easily and efficiently.</p>
                            <h3>IT Industry Services</h3>
                            <p>SalaryKart provides a wide range of IT services, including tech support, IT infrastructure management, and software development. Our expertise in the IT industry ensures that businesses remain technologically advanced and competitive.</p>
                            <h3>Advisory Services</h3>
                            <p>We offer a comprehensive range of business consulting services, such as market analysis, corporate strategy formulation, and financial assistance. We help businesses navigate complex situations and achieve their goals through informed decision-making and expert advice.</p>
                            <h3>Human Resources</h3>
                            <p>Our manpower solutions include staffing, recruitment, and human resource management. Our services ensure that businesses can hire skilled and reliable staff, improving productivity and operational efficiency. By adhering to these guidelines and understanding the scope of our offerings, you contribute to making SalaryKart a reliable and secure platform for all users. We value your cooperation and commitment to maintaining a respectful, safe, and legally compliant online community. This dedication allows us to provide outstanding services across all our offerings, ensuring a satisfying and productive experience for every user.</p>
                            <h2>SalaryKart Property Rights</h2>
                            <p>SalaryKart claims all rights to its platform, services, trademarks, trade names, and designs. This includes full control, ownership, and interest in all related intellectual property. Trademarks, service marks, and logos of other companies may also be displayed on the SalaryKart website, but their use without permission is prohibited and may have legal repercussions. It is important to understand that nothing in these terms grants you or any other party ownership rights over the platform. Access to SalaryKart's services is only possible while your account is active; otherwise, SalaryKart retains all rights. We appreciate your respect for these intellectual property rights.</p>
                            <h2>Associated Websites</h2>
                            <p>The SalaryKart platform may contain links to external websites run by third parties, known as "Linked Websites." We explicitly disclaim any responsibility for the accuracy of the content on these Linked Websites and the legitimacy of any links they may include. These Linked Websites are not part of the SalaryKart network. SalaryKart makes no claims or guarantees regarding the quality or reliability of the content on these Linked Websites, and the content is subject to change at any time without notice. Even if SalaryKart provides these links for convenience, it does not necessarily endorse the Linked Websites. Users on Linked Websites are solely responsible for their statements and activities. SalaryKart cannot be held responsible for any conversations or transactions that occur on Linked Websites because it is not a party to them. We appreciate your awareness of these considerations.</p>
                            <h2>Third-Party Promotions</h2>
                            <p>The SalaryKart platform may display advertisements from third parties. However, the presence of these advertisements does not indicate that SalaryKart endorses or recommends the advertiser, their products, or services. Users are encouraged to contact advertisers directly to learn more about their advertisements, products, or services. SalaryKart disclaims any liability that may result from interacting with these third-party advertisements. Users engage with third-party ads at their discretion, and SalaryKart is not responsible for any consequences that may arise.</p>
                            <h2>Recognizing Personal Property Rights</h2>
                            <p>The preservation of intellectual property rights is essential to the integrity of the SalaryKart platform. Duplication, replication, and exploitation of the platform or any of its services by other parties without authorization is forbidden. This includes any intellectual property—such as logos, trademarks, service marks, and other content—that SalaryKart owns or that appears on the platform. Upholding these rights ensures an equitable and lawful environment for all users.</p>
                            <h2>Additional Services</h2>
                            <h3>Additional Platform Features</h3>
                            <p>SalaryKart offers several extra services to enhance your experience, including blogs, chat rooms, reviews, comments, and other interactive elements. These services may be provided directly by SalaryKart or through affiliated businesses. Users must utilize these services lawfully and responsibly. Engaging in activities such as making threats, abusing, harassing, stalking, defaming, or supporting racial prejudice is strictly prohibited. We are committed to maintaining a respectful and secure environment for every user and will not tolerate actions that break the law or infringe on the privacy rights of others.</p>
                            <h3>Expert Views and Recommendations</h3>
                            <p>The platform may include recommendations, viewpoints, and insights from various specialists, analysts, and professionals. However, SalaryKart does not endorse or guarantee the veracity or accuracy of these concepts, conclusions, findings, assertions, or data. Users must conduct their own independent assessment of the appropriateness and accuracy of any goods, claims, advice, statements, or information encountered on the site. This ensures that your decisions are based on accurate and trustworthy information.</p>
                            <h3>No Expert Advice</h3>
                            <p>It is crucial to understand that nothing on the site should be interpreted as offering expert counsel on finance, investments, taxation, accounting, or legal matters. SalaryKart does not make specific suggestions or counsel. The platform's content may be added, removed, or altered at any time. SalaryKart disclaims all liability for any harm or claims resulting from these modifications. Users should consult with qualified professionals in the appropriate fields to address specific problems or challenges.</p>
                            <h3>Absence of Measure Adoption</h3>
                            <p>Nothing on the platform should be seen as supporting a specific strategy or plan of action. SalaryKart makes no express or implied warranties or guarantees that using any products or methods from the website won't violate any patents or other rights. By utilizing SalaryKart's platform and services, you accept these guidelines and commit to following them sensibly and lawfully. We value your cooperation and understanding in helping us maintain a lawful and safe online environment. If you need further assistance or have any issues, please contact us at info@salarykart.com</p>
                            <h2>Termination of Services</h2>
                            <p>SalaryKart reserves the right to stop offering its services under the following circumstances:</p>
                            <ul>
                                <li>Non-compliance: If any terms and conditions outlined in these agreements are broken.</li>
                                <li>Legal Prerequisites: If any laws require compliance by SalaryKart.</li>
                                <li>Service Termination: If SalaryKart chooses to discontinue the platform or cease providing services.</li>
                                <li>License Expiration: When the website's license expires.</li>
                                <li>Unresolved Business: To settle any unpaid debts.</li>
                            </ul>
                            <p>These termination circumstances are subject to the terms stated in this agreement. SalaryKart may monitor your activities after these conditions expire to ensure all obligations, including paying off any outstanding debts, are fulfilled. Upon termination of these conditions, you agree to stop using the site and its services immediately. Additionally, you relinquish any licenses and rights you may have received. Please note that you will still be liable for any outstanding payments even after the agreement expires. By using the SalaryKart platform and services, you acknowledge that you have read and agree to these termination terms. If you need further assistance or have any issues, please contact us at info@salarykart.com</p>
                            <h2>Refusing Warranties</h2>
                            <p>You acknowledge that using the SalaryKart platform and its services involves inherent risks. All services and platforms are provided "as is" and "as available." We expressly disclaim all implied warranties and guarantees, including merchantability, fitness for a particular purpose, and non-infringement. By downloading and using the content, you assume all associated risks, including potential damage to your computer system or loss of electronic data. Except for what is specifically stated in these Terms, SalaryKart makes no representations or guarantees, either expressed or implied, regarding the validity, completeness, or accuracy of any advice or information gained from our platform or services. Reporting abuse and ensuring responsible use of the platform and its services for private, non-commercial purposes are your responsibilities. Our firm disclaims all liability for any losses arising from the use of our services not specifically covered by these Terms.</p>
                            <ul>
                                <li>The platform's or services' appropriateness for your particular requirements.</li>
                                <li>The information's availability, security, timeliness, and correctness.</li>
                                <li>The accuracy or reliability of the results produced by the platform or services.</li>
                            </ul>
                            <p>We appreciate your understanding of these terms. By using the SalaryKart platform and services, you acknowledge and agree to these warranty conditions. Please contact us at info@salarykart.com. If you have any questions or require more information. We appreciate you selecting SalaryKart.</p>
                            <h2>Settlement</h2>
                            <p>By agreeing to these terms, you release and hold harmless SalaryKart, its affiliates, executives, agents, co-branders, employees, and partners from any demands or lawsuits, including all associated charges and legal fees. This indemnity covers any claims arising from your use or misuse of the SalaryKart platform or services, your breach of these conditions, your infringement of the rights of other users, your illegal acts, or any combination of these actions. SalaryKart and its affiliates are released from any demands or claims resulting from your actions or inactions as soon as you accept these conditions. We appreciate your cooperation and understanding in maintaining these requirements to ensure a safe and reliable service environment for all our offerings, including short-term loans, IT industry services, consultancy services, and labour services.</p>
                            <h2>Restrictions on Liability</h2>
                            <p>SalaryKart's officers, employees, agents, or service providers will not be held accountable for any damages, even when aware that such damages may occur. These losses could result from:</p>
                            <ul>
                                <li>Utilizing SalaryKart services or being unable to utilize them.</li>
                                <li>Prolonged delays in platform accessibility or service delivery.</li>
                                <li>The standard of services rendered, or the absence of them.</li>
                                <li>Any damage incurred from using the platform's content on your devices, whether through access or download.</li>
                                <li>Problems with the service's servers or other elements.</li>
                            </ul>
                            <p>We believe it's important to understand these disclaimer terms and appreciate your comprehension. To the fullest extent allowed by relevant law, you acknowledge that SalaryKart shall not be liable for any damages. The company does not cover any loss, shortfall, or disruption in platform operations arising from circumstances beyond its reasonable control, such as unexpected events, broken equipment or telecommunications, power outages, civil unrest, floods, and changes in government regulations. Acceptance of these conditions by you supports our aim to provide dependable and high-quality solutions for your operational and financial needs.</p>
                            <h2>Privacy Statement</h2>
                            <p>At SalaryKart, safeguarding the privacy and security of our esteemed customers is our top priority. We gather, handle, and arrange personal information to provide our services effectively. We have established a comprehensive privacy policy to fully address all your concerns. We invite you to study our comprehensive privacy policy by clicking the following link: [Privacy Statement Link]. We guarantee openness and confidence in our handling of your information by providing a thorough overview of our security procedures and privacy policies on this website.</p>
                            <h2>Modifications to the Agreement</h2>
                            <p>SalaryKart retains the right to modify, add to, suspend, or take other measures regarding these Terms and related content at our sole discretion and without prior notice. Any changes will be quickly communicated to users via website updates. By continuing to use our services or platform after such modifications, you agree to the revised conditions. We advise you to frequently review the terms to be aware of any adjustments or revisions. Your proactive cooperation is much appreciated.</p>
                            <h2>Law and Authority</h2>
                            <p>SalaryKart retains the right to alter, amend, delete, suspend, or modify these Terms and any related documents at our sole discretion and without prior notice. Any modifications will be quickly communicated to our users by updating our website with the updated terms. By continuing to use our platform or services after the revisions, you accept the updated terms. We advise you to check the terms from time to time to stay aware of any updates or changes. We appreciate your active participation.</p>
                            <ul>
                                <li><strong>Total Acknowledgment:</strong> Your relationship with SalaryKart is governed solely by the terms of service, privacy policy, and loan agreement. All the rights and obligations mentioned in these agreements are still ours.</li>
                                <li><strong>Protection of Rights:</strong> All rights and duties mentioned in these agreements are reserved by SalaryKart.</li>
                                <li><strong>Limitation Period:</strong> You understand and concur that any legal actions or claims resulting from your use of our services or acceptance of these terms will be permanently barred if not filed within a year of the incident.</li>
                                <li><strong>Dividability:</strong> The court of law shall attempt to uphold the parties' intentions as stated in this section if any part of these terms is determined to be invalid.</li>
                            </ul>
                            <h2>Action Suspended - Multiple Accounts</h2>
                            <p>It is strictly forbidden to open multiple customer accounts using different email addresses, devices, or addresses to fraudulently gain referral discounts, as stated in SalaryKart's Terms and Conditions.</p>
                            <h2>Use for Promotion</h2>
                            <p>By interacting with SalaryKart, you permit us to use your name, hometown, state, likeness, and/or voice for marketing and promotional reasons within legal parameters. This includes advertisements, social media posts, and other marketing collateral used to advertise SalaryKart's goods and services. With your permission, we may share your positive experiences with others in our community to enhance transparency and foster trust.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
