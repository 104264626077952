import React from "react";
import about_banner from "../images/about-banner.jpg";
import about_us_img from "../images/about-us.jpg";


export default function About(props) {
    return (
        <>
            <title>SalaryKart - {props.title}</title>
            <div className="innrer-banner">
                <img src={about_banner} style={{ width: "100%" }} alt="banner" />
            </div>

            <div className="about-are">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">

                            <h3>About Us</h3>
                            <p>SalaryKart is a reliable Fintech company that provides loans when you need them most. Emergencies and unexpected situations can arise at any time, leaving you in need of prompt financial assistance. In such moments, you need a reliable financial partner to provide swift and efficient loan solutions. We're here to be the trusted support you can rely on during your time of need.</p>

                            <p>With SalaryKart, you can secure loans ranging from ₹5,000 to ₹1,00,000 in just 30 minutes, completely hassle-free.</p>

                        </div>
                        <div className="col-md-6">
                            <img src={about_us_img} className="img-responsive thumbnail" alt="about-us" />
                        </div>


                    </div>

                </div>

            </div>
        </>
    );
}
