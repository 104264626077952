import React from "react";
import privacy_banner from "../images/privacy-banner.jpg";

export default function PrivacyPolicy(props) {
    return (
        <>
            <title>SalaryKart - {props.title}</title>
            <div className="privacy-banner">
                <img src={privacy_banner} style={{ width: "100%" }} alt="privacy" />
            </div>
            <div className="contact-line">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Privacy Policy</h1>
                            <p>At SalaryKart, we handle, process, and safeguard personal data according to strict ethical guidelines. Ensuring your safety and upholding the strictest privacy standards for your data are our top objectives. We respect your privacy and fully comply with data protection laws. We understand your concerns about the handling and sharing of personal data and prioritize gaining and preserving your trust.</p>
                            <h2>Scope</h2>
                            <p>This privacy statement applies to all SalaryKart programs, websites, and services (collectively, the "Website"). Please read our website's Terms of Use as well. By registering with us or using any means or device to access the Website, Application, or Services, you agree to this Policy. If you disagree with any part of this Policy, we respectfully ask that you do not use the Website, Application, Products, or any of our Services. SalaryKart values clarity and transparency.</p>
                            <h2>Consent</h2>
                            <p>By accepting this privacy statement, you give SalaryKart explicit permission to gather, store, use, disclose, and handle your personally identifiable information according to this Policy. Our main goal is to ensure you are aware of how your information is gathered, utilized, and shared when you visit our website or use our services. We prioritize your privacy at all times and manage your data with the utmost care and respect.</p>
                            <h2>Commitment</h2>
                            <p>Our mission at SalaryKart is to protect customer privacy while providing outstanding financial services and solutions. We operate with transparency and safe, ethical procedures to safeguard your information. By continuing to use our website or services, you agree to the collection, use, and sharing of your information as outlined in this Privacy Policy. Your trust in SalaryKart is our commitment.</p>
                            <h2>Data That We Gather</h2>
                            <p>At SalaryKart, we collect a range of data to improve our services while maintaining security and efficiency:</p>
                            <ul>
                                <li><strong>Individual Information:</strong> We value your privacy above all else. We use the data you give us, including your name, contact number, and birthdate, to safely deliver individualized support. Strict security protocols are in place to protect all personal data.</li>
                                <li><strong>Financial Specifics:</strong> We might need particulars about your credit card or bank account when you apply for a loan. Our top priority is to handle such private financial information with the utmost care, always following strict security procedures to safeguard your financial information.</li>
                                <li><strong>Transaction Records:</strong> All of your interactions with us, including loan requests and payment histories, are carefully recorded. This allows us to provide precise, customized services tailored to your specific requirements.</li>
                                <li><strong>External Connections:</strong> On rare occasions, you might provide us access to information stored on external platforms such as social media websites (like Facebook and Twitter). Access is permitted only with your express permission and in compliance with your selected privacy settings. We rigorously abide by our privacy policy while managing linked user accounts.</li>
                            </ul>
                            <p>SalaryKart is steadfast in our commitment to protecting the security and privacy of your data. We always protect your data using industry-standard security measures and abiding by all applicable laws and regulations. You may rely on SalaryKart to provide you with discreet and dependable financial solutions. Please do not hesitate to contact us if you have any questions regarding how we manage your information.</p>
                            <h2>How We Use the Data You Provide</h2>
                            <p>At SalaryKart, we use the information you confide in us in several vital ways to improve our customer support:</p>
                            <ul>
                                <li><strong>Processing Loans:</strong> The information you provide is essential to our thorough evaluation of loan applications. This helps us properly assess your eligibility and provide you with the best loan options possible.</li>
                                <li><strong>Client Contentment:</strong> Our group is committed to providing outstanding customer service. We use the data you provide to quickly respond to any questions, concerns, or problems, ensuring you are satisfied with our services.</li>
                                <li><strong>Observance of Regulations:</strong> Respecting the law is very important to us. Your information is essential to procedures like identity verification, preventing fraud, and adhering to anti-money laundering laws.</li>
                                <li><strong>Improving the Website and Services:</strong> In an ongoing effort to improve user experience, we examine non-personal data and user behaviour to enhance website functionality and services, providing you with a smooth experience.</li>
                            </ul>
                            <p>Information security is a top priority for SalaryKart. You can be sure that we always prioritize your privacy and security, handling your data with the highest care and discretion. For reliable financing solutions supported by strict data protection procedures, turn to SalaryKart. Please contact us if you have any questions about how we handle data. Our commitment is to earn your trust.</p>
                            <h2>Information Exchange Guidelines</h2>
                            <p>Protecting your information and maintaining your privacy are our top priorities at SalaryKart. We handle the dissemination of your information as follows:</p>
                            <ul>
                                <li><strong>Reliable Partners:</strong> From time to time, we collaborate with reputable service providers to assist various areas of our business, including lenders, credit bureaus, and payment processors. You can be assured that we carefully choose our partners and ensure they adhere to strict data privacy guidelines.</li>
                                <li><strong>Legal Obligations:</strong> Under certain conditions, such as in response to a subpoena or court order, we may be required by law to disclose personal information. We strictly abide by the law and take these obligations seriously.</li>
                                <li><strong>Business Transitions:</strong> To ensure service continuity, your information might be transferred to the new company in the event of a merger, sale, or other business action involving SalaryKart. Strict security measures are implemented during such changes to protect your data.</li>
                            </ul>
                            <p>It's crucial to know that SalaryKart doesn't transfer or sell your personally identifiable information to third parties or affiliates for advertising.</p>
                            <h2>Policy for Data Retention</h2>
                            <p>At SalaryKart, we handle your personal information with the highest care and responsibility. We only retain your information for as long as necessary to fulfil the purposes for which it was collected and to satisfy legal requirements. Your privacy is our priority, and we rigorously abide by all applicable laws and regulations regarding our data retention policy. You can trust us to uphold strict policies to protect your privacy and ensure the accuracy of your information. Your data is handled with the utmost care and concern at SalaryKart.</p>
                            <h2>Your Legal Rights</h2>
                            <p>Protecting your right to privacy and data protection is our first priority at SalaryKart. We are committed to ensuring that your rights regarding the protection and use of your data are upheld. These are your main rights concerning data:</p>
                            <ul>
                                <li><strong>Access to Your Data:</strong> You have the right to access the personal data SalaryKart has on file. This enables you to verify that the information we have collected is accurate and complete.</li>
                                <li><strong>Making Edits or Removing Data:</strong> You have the right to request that any incomplete or incorrect personal information be corrected. You can also request the data be deleted if it is no longer necessary for the original purpose it was collected. At SalaryKart, we empower you to manage your data and ensure it serves your purposes.</li>
                                <li><strong>Removing Consent:</strong> We respect your choice to stop permitting us to use your information. SalaryKart will stop processing your data if you decide to revoke your consent, even if it means we won't be able to continue offering certain services. We respect your right to govern your data and will always honour your decisions.</li>
                            </ul>
                            <p>At SalaryKart, we are steadfast in our commitment to upholding your rights and adhering to all applicable laws and regulations. Our top concerns are protecting your privacy and ensuring your data is secure. You can rely on us to treat your data with the highest care and attention.</p>
                            <h2>Resolution of Disputes</h2>
                            <p>At SalaryKart, we value openness and fairness in the settlement of any disagreements or problems that may arise. Indian law governs our privacy policies and any disputes about them. The Delhi courts will hear any legal issues by Indian legal rules. We are dedicated to ensuring the dispute resolution process is fair and transparent. We uphold uniformity and transparency in resolving potential conflicts by adhering to relevant laws and regulations. Ensuring your comfort and trust throughout the process is our main priority.</p>
                            <h2>Cookies</h2>
                            <p>SalaryKart uses cookies to improve your online experience and the services we provide. You can be confident that we respect your privacy and will only use cookies with your permission. By understanding your preferences and interests, these cookies enable us to offer personalized solutions. We continuously improve and customize our services to match your needs by examining user behaviours and preferences. We rigorously uphold our privacy policy to safeguard your data and prevent unauthorized access. We respect your right to privacy and will only provide you with information about our offerings if you request it. Your trust and satisfaction are our primary concerns.</p>
                            <h2>Safety Protocols</h2>
                            <p>Protecting your information from unwanted access, use, or disclosure is our top priority at SalaryKart. We employ strict security protocols to preserve the confidentiality and integrity of your information. While we work hard to provide the highest level of security, it's important to recognize that no online communication can be completely secure. Even with our best efforts, we cannot guarantee complete protection. To stay ahead of new threats and ensure robust data protection, we constantly assess and improve our security procedures.</p>
                            <h2>Changes to This Privacy Statement</h2>
                            <p>SalaryKart is committed to maintaining privacy standards compliant with applicable laws as well as our policies. We retain the right to make necessary modifications to this privacy statement as part of our commitment to transparency. To stay up to date on our most recent privacy practices, we invite you to regularly examine our privacy statement. Any modifications that affect how we handle your personal information will be made transparent and communicated. By continuing to use our services after these changes, you automatically accept the updated terms.</p>
                            <h2>Contact with our Grievance Officer</h2>
                            <p>At SalaryKart, we prioritize quickly and effectively resolving any concerns or problems you may have with our services. The following contact information for our Grievance Officer is provided:</p>
                            <ul>
                                <li><strong>Name:</strong> Meena Joshi</li>
                                <li><strong>Mobile Number:</strong> 9174748989</li>
                                <li><strong>Email Address:</strong> meena.joshi@salarykart.com</li>
                            </ul>
                            <h2>Our Declaration to You</h2>
                            <p>Your satisfaction and comfort are our first concerns at SalaryKart. We invite you to contact our Grievance Officer if you need help or have any questions about our services. They are committed to making your experience with us enjoyable and seamless and will help you promptly resolve any problems.</p>
                            <h2>Modifications to the Agreement</h2>
                            <p>SalaryKart retains the right to modify, add to, suspend, or take other measures regarding these Terms and related content. Any changes will be quickly communicated to users via website updates. You agree to the revised conditions if you keep using our services or platform after such modifications. It is advised that you frequently review the terms to be aware of any adjustments or revisions. Your proactive cooperation with us is much appreciated.</p>
                            <h2>GET IN TOUCH</h2>
                            <p>Please don't hesitate to get in touch with our Grievance Officer if you have any queries or worries. We work hard to make sure every encounter with SalaryKart lives up to your expectations, and we value your faith in us.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
