import React, { useState, useEffect } from "react";
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import logo from "../images/salary_kart_logo.png";
import '../styles/style.css';
import '../styles/my-css.css';
import '../styles/bootsnav.css';
import '../styles/animate.css';

export default function Header() {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const styles = {
        headerTop: {
            backgroundColor: 'rgb(27, 20, 100)',
            padding: '0.3rem 0',
        },
        container: {
            width: '100%',
            maxWidth: '1200px',
            margin: '0 auto',
            padding: '0 1rem',
        },
        flexContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        contactInfo: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
        },
        contactLink: {
            marginRight: '1rem',
            fontSize: '0.875rem',
            color: '#ffffff',
        },
        socialIcons: {
            display: 'flex',
            justifyContent: 'center',
        },
        socialLink: {
            marginLeft: '0.5rem',
            color: '#ffffff',
        },
        mainHeader: {
            backgroundColor: '#ffffff',
            boxShadow: '0 1px 3px 2px rgba(0, 0, 0, 0.1)',
            position: 'sticky',
            padding: '0.2rem 0',
            top: 0,
            zIndex: 50,
        },
        logo: {
            height: isMobile ? '2.4rem' : '3rem',
            transition: 'height 0.3s ease',
        },
        navMenu: {
            display: 'flex',
            alignItems: 'center',
            fontSize: isMobile ? '0.9rem' : '1rem',
            fontWeight: 800,
        },
        navLink: {
            color: '#4b5563',
            textDecoration: 'none',
            padding: '0.5rem 1rem',
            whiteSpace: 'nowrap',
        },
        applyButton: {
            backgroundColor: isHovered ? '#3b82f6' : '#f77f00',
            color: '#ffffff',
            padding: isMobile ? '0.4rem 0.8rem' : '0.5rem 1rem',
            borderRadius: '0.25rem',
            textDecoration: 'none',
            fontSize: isMobile ? '0.9rem' : '1rem',
            transition: 'all 0.3s ease',
            cursor: 'pointer',
        },
        mobileMenuButton: {
            display: isMobile ? 'block' : 'none',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
        },
        sidebar: {
            position: 'fixed',
            top: 0,
            left: isSidebarOpen ? 0 : '-100%',
            width: '250px',
            height: '100%',
            backgroundColor: '#ffffff',
            boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
            transition: 'left 0.3s ease-in-out',
            zIndex: 1000,
        },
        sidebarClose: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '1.5rem',
            cursor: 'pointer',
        },
        sidebarNav: {
            display: 'flex',
            flexDirection: 'column',
            padding: '2rem 1rem',
        },
        sidebarLink: {
            color: '#4b5563',
            textDecoration: 'none',
            padding: '0.5rem 0',
            borderBottom: '1px solid #e5e7eb',
        },
        rightSection: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: 'auto',
        },
    };

    return (
        <>
            {!isMobile && (
                <div style={styles.headerTop}>
                    <div style={styles.container}>
                        <div style={styles.flexContainer}>
                            <div style={styles.contactInfo}>
                                <a href="#" style={styles.contactLink}><FontAwesomeIcon icon={faPhone} /> +91-91-74-74-89-89</a>
                                <a href="mailto:info@salarykart.com" style={styles.contactLink}><FontAwesomeIcon icon={faEnvelope} /> info@salarykart.com</a>
                            </div>
                            <div style={styles.socialIcons}>
                                <a href="#" style={styles.socialLink}><FontAwesomeIcon icon={faFacebook} /></a>
                                <a href="#" style={styles.socialLink}><FontAwesomeIcon icon={faTwitter} /></a>
                                <a href="#" style={styles.socialLink}><FontAwesomeIcon icon={faInstagram} /></a>
                                <a href="#" style={styles.socialLink}><FontAwesomeIcon icon={faYoutube} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <header style={styles.mainHeader}>
                <div style={styles.container}>
                    <div style={{ ...styles.flexContainer, justifyContent: isMobile ? 'space-between' : 'flex-start' }}>
                        {isMobile && (
                            <button style={styles.mobileMenuButton} onClick={toggleSidebar}>
                                <FontAwesomeIcon icon={faBars} />
                            </button>
                        )}
                        <Link to="/" style={{ display: 'inline-block', margin: isMobile ? '0 auto' : '0' }}>
                            <img src={logo} alt="salary-cart" style={styles.logo} />
                        </Link>
                        {!isMobile && (
                            <div style={styles.rightSection}>
                                <nav style={styles.navMenu}>
                                    <NavLink to="/" style={styles.navLink} onClick={scrollToTop}>HOME</NavLink>
                                    <NavLink to="/about-us" style={styles.navLink} onClick={scrollToTop}>ABOUT US</NavLink>
                                    <NavLink to="/contact-us" style={styles.navLink} onClick={scrollToTop}>CONTACT US</NavLink>
                                    <NavLink to="/repay-loan" style={styles.navLink} onClick={scrollToTop}>REPAY LOAN</NavLink>
                                </nav>
                                <Link
                                    to="/apply-now"
                                    style={styles.applyButton}
                                    onClick={scrollToTop}
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    Apply Now
                                </Link>
                            </div>
                        )}
                        {isMobile && (
                            <Link
                                to="/apply-now"
                                style={styles.applyButton}
                                onClick={scrollToTop}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                Apply Now
                            </Link>
                        )}
                    </div>
                </div>
            </header>
            {isMobile && (
                <div style={styles.sidebar}>
                    <button style={styles.sidebarClose} onClick={toggleSidebar}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <nav style={styles.sidebarNav}>
                        <NavLink to="/" style={styles.sidebarLink} onClick={() => { scrollToTop(); toggleSidebar(); }}>HOME</NavLink>
                        <NavLink to="/about-us" style={styles.sidebarLink} onClick={() => { scrollToTop(); toggleSidebar(); }}>ABOUT US</NavLink>
                        <NavLink to="/contact-us" style={styles.sidebarLink} onClick={() => { scrollToTop(); toggleSidebar(); }}>CONTACT US</NavLink>
                        <NavLink to="/repay-loan" style={styles.sidebarLink} onClick={() => { scrollToTop(); toggleSidebar(); }}>REPAY LOAN</NavLink>
                    </nav>
                </div>
            )}
        </>
    );
}
