import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function EligibilityFailed() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const refNumber = query.get('ref');
    const [dateTimeStamp, setDateTimeStamp] = useState('');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const now = new Date();
        const dateString = now.toLocaleDateString();
        const timeString = now.toLocaleTimeString();
        setDateTimeStamp(`${dateString} ${timeString}`);

        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isSmallScreen = windowWidth <= 768;

    const styles = {
        pageContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            //   minHeight: '100vh',
            padding: '20px',
            boxSizing: 'border-box',
        },
        container: {
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            padding: isSmallScreen ? '20px' : '40px',
            textAlign: 'center',
            maxWidth: isSmallScreen ? '100%' : '400px',
            width: '100%',
            boxSizing: 'border-box',
            //   marginTop: '50px', // Added top margin
        },
        emoji: {
            fontSize: isSmallScreen ? '48px' : '64px',
            marginBottom: '20px',
        },
        heading: {
            color: '#e74c3c',
            marginBottom: '20px',
            fontSize: isSmallScreen ? '1.2rem' : '1.5rem',
        },
        text: {
            color: '#333',
            lineHeight: '1.6',
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
        },
        button: {
            backgroundColor: '#3498db',
            color: 'white',
            border: 'none',
            padding: isSmallScreen ? '8px 16px' : '10px 20px',
            borderRadius: '4px',
            textDecoration: 'none',
            display: 'inline-block',
            marginTop: '20px',
            fontSize: isSmallScreen ? '0.9rem' : '1rem',
            transition: 'background-color 0.3s',
        },
    };

    return (
        <div style={styles.pageContainer}>
            <div style={styles.container}>
                <div style={styles.emoji}>❌</div>
                <h1 style={styles.heading}>Eligibility Failed</h1>
                <p style={styles.text}>
                    We're sorry, but you do not meet the eligibility criteria for this service.
                    Please review the requirements and try again if you believe this is an error.
                </p>
                {refNumber && (
                    <p style={styles.text}>
                        Reference Number: {refNumber}
                    </p>
                )}
                <p style={styles.text}>
                    Date and Time: {dateTimeStamp}
                </p>
                <a
                    href="/"
                    style={styles.button}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#2980b9'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#3498db'}
                >
                    Back to Home
                </a>
            </div>
        </div>
    );
}
